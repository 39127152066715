<template>
    <div>
        <div class="workdayShopD">
            <div class="workdayShopD_box">
                <div class="workdayShopD_di">
                    <div>
                        <img src="../../../assets/img/din.png" alt="">
                        <h2>碳鱼烧烤（丰台分店）</h2>
                    </div>
                    <div>
                        <a @click="xinZen">新增</a>
                        <a @click="cancel_surface">删除</a>
                        <a @click="amesssnd()">修改</a>
                    </div>
                </div>
            </div>
            <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    height="450"
                    @selection-change="handleSelectionChange"
                  >
                    <el-table-column type="selection" width="155"> </el-table-column>

                    <el-table-column prop="serial" label="角色" width="230">
                    </el-table-column>
                    <el-table-column prop="bumen" label="工作日设置" width="320">
                        <template slot-scope="scope">
                            <div>
                                <el-input disabled v-model="scope.row.value1" ></el-input>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="type" label="休息天数" width="300">
                        <template slot-scope="scope">
                            <div>
                                <el-input disabled v-model="scope.row.day" placeholder="1" value="1"></el-input>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="entrepot" label="工作时间" width="695">
                        <template  slot-scope="scope">
                           <el-time-picker
                           disabled
                                is-range
                                v-model="scope.row.value2"
                                range-separator="至"
                                :start-placeholder="scope.row.kaishgi"
                                :end-placeholder="scope.row.jieshu"
                                placeholder="选择时间范围">
                            </el-time-picker>
                        </template>
                    </el-table-column>

            </el-table>
        </div>
        <div class="tishi">提示： 若员工轮休采取轮休制，则设置周一到周日均为工作日</div>

        <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false" >
            <div class="working_days">
                <h2><span></span>新增工作日设置</h2>
                <div>
                    <p><span>*</span>选择角色:</p>
                     <el-select v-model="role_value" @change="role_valueCheang" placeholder="请选择">
                        <el-option
                        v-for="item in role"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <p><span>*</span>选择工作日:</p>

                    <el-select v-model.trim ="workday_value" @change="workday_valueCheang" multiple placeholder="请选择">
                        <el-option
                        v-for="item in workday"
                        :key="item.value"

                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div>

                    <p><span>*</span>休息天数: </p>
                    <el-input v-model="days" placeholder="请输入"></el-input>
                </div>
                <div>

                    <p><span>*</span>选择工作时间:</p>
                      <el-time-picker
                          is-range
                          format='HH:mm'
                          value-format = 'HH:mm'
                            @change="time_box"
                            v-model="time_S"
                          range-separator="至"
                          :start-placeholder="kaishi"
                          :end-placeholder="jieshuS"
                          placeholder="选择时间范围"
                          >
                      </el-time-picker>
                </div>
                <el-button @click="New_Working()" type="danger" round v-if="bao">保存</el-button>
                <el-button @click="amend_Working()" type="danger" round v-if="xiu">修改</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { query_workday_set_list,update_workday_set,delete_workday_set,insert_workday_set } from '@api/pulic'

export default {
  name: 'Workday',
  data() {
    return {
      bao:true,
      xiu:false,
      kaishi:'',
      jieshuS:'',
aaa:"1",
      tableData: [

      ],
      drawer: false,
      // 角色
      role: [],
      roleS:{}, // 角色
      role_value: '', // 选择角色的value
      //  选择工作日
      workday_S:{},
      workday_value: '',
      workday: [{
        value: '1',
        label: '周一'
      }, {
        value: '2',
        label: '周二'
      }, {
        value: '3',
        label: '周三'
      }, {
        value: '4',
        label: '周四'
      }, {
        value: '5',
        label: '周五'
      }, {
        value: '6',
        label: '周六'
      }, {
        value: '7',
        label: '周日'
      }],
      // 休息天数
      days: '',
      // 选择时间
      time_S:  [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 22, 40)],
      time_sss:'',

      P_id:'',
      gongZr:'',

      sahngBang:[],
      list_set:'',
      AddKit:{
        role_value:'',
        workday_value:'',
        days:'',
        time_S:''
      }
    }
  },
  created(){
   
    this.P_id = this.$route.params.sss
   
    sessionStorage.setItem('P_id',this.$route.params.sss)
    console.log(sessionStorage.getItem('P_id'))

    // 加载方案列表
    this.liebiao()
  },
  watch:{
    drawer(val){
      if(val){
        this.emit()
      }
    },

  },
  methods: {
    emit(){

      this.AddKit={
         role_value:'',
        workday_value:'',
        days:'',
        time_S:''
      }
      this.role_value=''
      this.workday_value=''
      this.days=''
      this.time_S=''
     },
    xinZen(){
      this.drawer = true
      this.bao = true
      this.xiu = false
    },

    amesssnd(){
      if(this.list_set.length == 0){
        this.$message({
         message: '您请选择一个设置进行修改',
         type: 'waring'
       })
      } else{
         this.drawer = true
         this.bao = false
         this.xiu = true

         setTimeout(()=>{
           this.role_value = '小明' //  ssssssssssssssssssssssssssssssssssssssssssssssssss 有问题
         this.workday_value = this.list_set[0].value1.split(',')
         this.days = this.list_set[0].days
          console.log(this.days,this.list_set[0].kaishgi)
         this.kaishi = this.list_set[0].kaishgi
         this.jieshuS = this.list_set[0].jieshu
         },120)
      }
    },


    amend_Working(){
         let ss = this.list_set[0].set_idA
         if(!this.roleS.label || !this.workday_value || !this.days || !this.time_S || !this.time_S){
           alert(1)
           console.log(this.roleS.label ,this.workday_value ,this.days ,this.time_S ,this.time_S)
           return
         }else{
           alert(2)
           update_workday_set({
            data:{
                ent_id: this.$store.state.ent_id, // 企业
                user_id:this.$store.state.user_id,
                plan_id: sessionStorage.getItem('P_id'), // 桌台
                role_id: '45',	//角色id
                set_id: ss,
                role_name: this.roleS.label, //角色名称
                workday:  this.workday_value.toString(), //工作日，1-7，逗号分割
                days_rest: this.days,  //每周休息天数
                time_start: this.time_S[0],  //上班时间
                time_end: this.time_S[1] //下班时间
            }
          }).then( res => {
            console.log(res)
            if(res.code == 200){
              this.emit();
              this.$message({
                type: 'success',
                text: '成功'
              })
            }
          })
         }
    },


    // handleSelectionChange(val) {
    //   this.multipleSelection = val
    // },
    // 新增工作日设置   this.roleS.label
    role_valueCheang(i){
      this.role.forEach((item)=>{
        if(item.value == i){
          this.roleS = item
        }
      })
      console.log( this.roleS.label);
    },

    handleSelectionChange(val){
      console.log(val)
      this.list_set=val

      // this.list_set = val.map(x=>x.set_idA)
      // console.log(this.list_set)
      // let set_idA_s = ...val.set_idA
      // sessionStorage.setItem('set_idA',...val.set_idA)
    },

    // 删除列表
    cancel_surface(){
     let param = {
          ent_id: this.$store.state.ent_id,
          set_id: this.list_set.map(x=>x.set_idA).join(','),
          user_id:this.$store.state.user_id
        }
        console.log(param);
      delete_workday_set({
        data:param
      }).then( res => {
        console.log(res)
        this.liebiao()
      })
    },

    // 工作日   obj
    workday_valueCheang(vid){
      console.log(vid);
      let obj = []
      this.workday.forEach(item => {
        vid.forEach(itema => {
          if(itema == item.value){
            obj.push(item.label)
          }
        });
      });
      console.log(obj.toString())
      this.gongZr = obj.toString()
    },

    // 时间
    time_box(i){
      // console.log(i)
      console.log(this.time_S)
      this.time_sss = this.time_S[0] + '-' + this.time_S[1]
      console.log(this.time_sss )
    },

    New_Working(){
      insert_workday_set({
        data:{
          ent_id: this.$store.state.ent_id, // 企业
          user_id:this.$store.state.user_id,
          plan_id: sessionStorage.getItem('P_id'), // 桌台
          role_id: '45',	//角色id
          role_name: this.roleS.label.toString(), //角色名称
          workday:  this.gongZr, //工作日，1-7，逗号分割
          days_rest: this.days,  //每周休息天数
          time_start: this.time_S[0].toString(),  //上班时间
          time_end: this.time_S[1].toString() //下班时间
        }
      }).then( res => {
        console.log('渲染方案列表',res)
        // console.log(this.days)
        // console.log(sessionStorage.getItem('P_id'))
        // console.log(this.roleS.label.toString())
        // console.log(this.gongZr)
        // console.log(this.time_S[0].toString())
        // console.log(this.time_S[1].toString())
        if(res.code == 200){
          this.emit()
          this.drawer=false
        }
        this.liebiao()
      })
    },

    // 渲染列表
    liebiao(){
      query_workday_set_list({
      data:{
        ent_id: this.$store.state.ent_id,
        plan_id:  sessionStorage.getItem('P_id'),
      }
      }).then( res => {
        console.log(res)
        // console.log(res.body.data)
        let arr = res.body.data
        this.tableData=[]


        arr.forEach(item=>{
          let objS={
            serial:item.role_name , // 角色
            days: item.days_rest, // 休息天数
            value1: item.workday,
            day: item.days_rest,
            kaishgi:item.time_start,
            jieshu:item.time_end,
            set_idA:item.set_id

          }
          this.tableData.push(objS)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-main {
    
    background: #f2f6fc;
}
.workdayShopD{
    background: #fff;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;
    padding: 0 0 200px 0;
    box-sizing: border-box;
    border-radius: 16px;
    .workdayShopD_box{
        .workdayShopD_di{
            display: flex;
            height: 80px;
            background: #fff;
            border-radius: 15px 15px 0px 0px;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            box-sizing: border-box;
            h2{
                font-size: 18px;
                font-family: Adobe Heiti Std;
                font-weight: 600;
                color: #333333;
                line-height: 30px;
            }
            img{
                width: 23px;
                height: 30px;
                margin-right: 16px;
            }
            div{
                display: flex;
                a{
                   width: 100px;
                    height: 44px;
                    border: 1px solid #FF0000;
                    border-radius: 10px;
                    display: block;
                    font-size: 16px;
                    color: #FF4141;
                    line-height: 44px;
                    text-align: center;
                    margin-left: 20px;
                }
            }
        }
    }
}
.working_days{
    padding: 10px;
    h2{
        position: relative;
        color: #333;
        font-size: 20px;
        font-weight: 400;
        padding-left: 12px;
        margin-top: 20px;
        margin-bottom: 40px;
        span{
            display: block;
            position: absolute;
            left: 0;
            top: -7px;
            width: 6px;
            height: 32px;
            background: #FF0000;
            border-radius: 3px;
        }
    }
    div{
        margin-bottom: 10px;
        display: flex;
        & > p{
            font-size: 16px;
            color: #333;
            width: 130px;
            text-align: right;
            position: relative;
            margin: 10px;
            span{
                color: #FF4141;
                font-size: 16px;
                margin-right: 4px;
            }
        }
    }
}
</style>
